<template>
    <div class="personal">
        <!-- 个人中心 -->
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="ruleForm.oldPassword" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { updatePass } from '../../api/personal'
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
                oldPassword: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, required: true, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, required: true, trigger: 'blur' }
                ],
                oldPassword: [
                    { required: true, message: '旧密码不能为空！', trigger: 'blur' },
                ]
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    // console.log(this.ruleForm);
                    let info = { id: JSON.parse(sessionStorage.getItem('userInfo')).id, oldPassword: this.ruleForm.oldPassword, password: this.ruleForm.pass }
                    console.log(info)
                    const res = await updatePass(info);
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功，请前往登录页面重新登录！',
                            type: 'success'
                        });
                        setTimeout(() => {
                            sessionStorage.clear();
                            this.$router.push('/');

                        }, 3000);
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error'
                        });
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
.personal {
    padding: 20px;

    .el-input {
        width: 500px;
    }

    .el-form-item {
        margin-bottom: 20px !important;
    }
}
</style>